/** @jsx jsx */
import PropTypes from 'prop-types'
import React from 'react'
import { jsx } from 'theme-ui'
import ContentHero from '~/components/Content/Hero'
import ContentSection from '~/components/Content/Section'
import { ScrollSnaps } from '~/components/Generic/ScrollSnap'

const ContentPage = ({ page }) => {
  return (
    <>
      {page.hero && <ContentHero hero={page.hero} />}

      {page.scrollSnapSections && (
        <ScrollSnaps>
          {page.scrollSnapSections.map((section, i) =>
            section.__typename === 'ContentfulHero' ? (
              <ContentHero hero={section} key={section.id + i} />
            ) : (
              <ContentSection
                section={{ ...section, blog: page.blog }}
                key={section.id + i}
              />
            )
          )}
        </ScrollSnaps>
      )}

      {page.sections?.map((section, i) => (
        <ContentSection
          key={section.id + i}
          section={{ ...section, blog: page.blog }}
        />
      ))}
    </>
  )
}

ContentPage.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })
}

export default ContentPage
