import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ContentPage from '~/components/Content/Page'
import { Box } from '@theme-ui/components'

const LookbookTemplate = ({ data }) => {
  const { lookbook } = data

  return (
    <Layout breadcrumb={{ crumbLabel: 'Lookbook' }}>
      <Metadata
        title={lookbook.metaTitle || lookbook.title}
        description={
          lookbook.metaDescription?.metaDescription ||
          `Super73's ${lookbook.title} page`
        }
      />

      <Box mt="-3.2rem">
        <ContentPage page={lookbook} />
      </Box>
      <div style={{ height: '30px' }} />
    </Layout>
  )
}

export const query = graphql`
  query LookbookQuery($slug: String) {
    lookbook: contentfulPage(slug: { eq: $slug }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      hero {
        ...ContentHeroFragment
      }
      ...ContentPageFragment
    }
  }
`

export default LookbookTemplate
